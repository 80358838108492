import React, { useState, useEffect } from "react";
import Icon from "./Icon";
import { useQueryParam, ArrayParam } from "use-query-params";

// import FilteringTechStacksPanel from "./FilteringTechStacksPanel";

// markup
const WorksPage = ({ allWpWorksPostData, currentlyCheckedTermsArr }) => {
  const worksPostArr = allWpWorksPostData.data.allWpWork.edges;
  const techStacksArr = allWpWorksPostData.data.allWpTechStack.nodes;

  // チェックボックスの値の状態
  let initialCheckedStatus = {};
  techStacksArr.forEach(e => {
    initialCheckedStatus[e.slug] = false;
  });
  const [checkedStatus, setCheckedStatus] = useState(initialCheckedStatus);

  const handleChange = e => {
    const newCheckedStatus = Object.assign({}, checkedStatus, {
      [e.target.value]: !checkedStatus[e.target.value]
    });
    setCheckedStatus(newCheckedStatus);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(checkedStatus)
    // val の値でtrueのkeyを取り出す
  }

  // 検索パネル
  const FilteringTechStacksPanel = () => {
    return (
        <form action="/works" method="GET" className="p-search-form" onSubmit={handleSubmit}>
          <dl>
            <div>
              <dt>担当</dt>
              <dd>
                {
                  techStacksArr.map((item, index) => {
                    index = index + 1;
                    return (
                      <label
                        htmlFor={`id_${index}`}
                        key={index}
                        className="checkbox">
                        <input
                          type="checkbox"
                          value={item.slug}
                          id={`id_${index}`}
                          checked={checkedStatus[item.slug]}
                          onChange={handleChange}
                          name="tech_stacks" /><span>{item.name}</span>
                      </label>
                    )
                  })
                }
              </dd>
            </div>
          </dl>
          {/* <button type="submit" className="c-btn--primary">
            send
          </button> */}
        </form>
    )

  }

  // 各案件データ
  // レンダリングされるのは
  // - checkedStatusがすべてfalseの場合は投稿全件
  // - checkedStatusにtrueのkeyが存在する場合は、そのkeyと同一名称のタームを持つ投稿
  const WorksList = () => {
    const worksToBeRendered = [];
      
    for (let i in worksPostArr) {
      let res = 0;
      let termExists = []
        ; let data = worksPostArr[i].node;
      let techStacksOnThePost = data.techStacks.nodes;

      let checkedStatusArr = [];
      for ( let key of Object.keys(checkedStatus) ) {
        if ( checkedStatus[key] === true ) {
          checkedStatusArr.push( key );
        }
      }

      if (checkedStatusArr) {
        termExists = techStacksOnThePost.filter(x => checkedStatusArr.includes(x.slug))
      }
      if ( checkedStatusArr.length == 0 || termExists.length > 0) {
        res = 1;
      }

      if (res > 0) {
        worksToBeRendered.push(
          <div className="c-workspost" key={i}>
            <h2 className="c-wokspost__title">
              <span className="period-and-tags">
                <span className="period">{data.works.period}</span>
                <span className="techStacksList">
                  {data.techStacks.nodes.map((item, idx) => {
                    return (
                      <span key={idx} className={item.slug}>
                        <Icon name={item.slug} />
                        &nbsp;
                      </span>
                    );
                  })}
                </span>
              </span>
              <span className="title">
                {/* <Link to={`/works/` + data.databaseId}>{data.title}</Link> */}
                {data.title}
              </span>
            </h2>
            <div className="c-wokspost__body">
              <dl className="c-wokspost__body__data">
                <dt>URL</dt>
                <dd>
                  <a
                    href={data.works.url}
                    rel="nofollow noreferrer"
                    target="_blank"
                  >
                    {data.works.url}
                  </a>
                </dd>
                <dt>概要</dt>
                <dd>{replaceToListItem(data.works.summary)}</dd>
                <dt>担当</dt>
                <dd>
                  <dl>
                    <dt>HTML CSS</dt>
                    <dd>{data.works.inChargeOf.htmlCss}</dd>
                    <dd>
                      {replaceToListItem(data.works.inChargeOf.htmlCssTopics)}
                    </dd>
                    <dt>JavaScript</dt>
                    <dd>{data.works.inChargeOf.javascript}</dd>
                    <dd>
                      {replaceToListItem(data.works.inChargeOf.javascriptTopics)}
                    </dd>
                    <dt>WordPress</dt>
                    <dd>{data.works.inChargeOf.php}</dd>
                    <dd>{data.works.inChargeOf.phpTopics}</dd>
                  </dl>
                </dd>
              </dl>
            </div>
          </div>
        );
      }
    }
    return (
      <div>
        <p>該当：{worksToBeRendered.length}件</p>
        {worksToBeRendered}
        </div>
    );
  }

  return (
    <div>
      <FilteringTechStacksPanel />
      <WorksList />
    </div>
  );
};

const replaceToListItem = (str) => {
  let arr = [];
  if ( str ) {
    str.split("\n").map((s, i) => {
      arr.push(<li key={i}>{s}</li>);
    });
  }
  return <ul>{arr}</ul>;
};

export default WorksPage;
