import * as React from "react";
import { graphql } from "gatsby";
import { useQueryParam, ArrayParam } from "use-query-params";

import AuthContent from "../components/AuthContent";
import Layout from "../components/Layout";
import WorksPage from "../components/Works";

export const query = graphql`
  {
    allWpTechStack {
      nodes {
        slug
        name
      }
    }
    allWpWork {
      edges {
        node {
          title
          databaseId
          id
          slug
          techStacks {
            nodes {
              name
              slug
            }
          }
          works {
            url
            summary
            period
            opsOnly
            ops
            inChargeOf {
              htmlCss
              phpTopics
              php
              javascriptTopics
              javascript
              htmlCssTopics
            }
          }
        }
      }
    }
  }
`

const Works = ( data )=> {
  const [techStacks, setTechStacks] = useQueryParam("tech_stacks", ArrayParam);

  return (
    <Layout pageTitle="Works" pageSlug="works">
      <AuthContent>
        <WorksPage allWpWorksPostData={data} currentlyCheckedTermsArr={techStacks} />
      </AuthContent>
    </Layout>
  );
}

export default Works;
